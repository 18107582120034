import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Header from "../components/header";

import Tabs from "../components/tabsWhoWeAre";

const AboutUs = () => (
  <Layout>
    <Seo title="Nosotros" />
    <Header />
    <Tabs selection="cenfarte" />
  </Layout>
);

export default AboutUs;
